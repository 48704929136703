import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
import Button from "components/ui/atoms/button/button";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step51 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Agrogardening and Flower Arrangements | ${titleTrainingDays}`}
        description={"Technical video about this profesional field "}
        canonical={"training-days/step-5-1/"}
      />
      <div className="container step">
        <p className={"h1"} id={"title"}>
          {titleTrainingDays} - Step 5.1{" "}
        </p>
        <h1 className={"slideLeft"}>Agrogardening and Flower Arrangements</h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <LazyIframe src={"uHngVOumnZM"} title={"Agrogardening and Flower Arrangements"} />
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-5-0/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-5-2/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>Technical video about this professional field</p>
            <a
              className={"pdf"}
              href={"/downloads/CERTIFICADO-PROFESIONALIDAD-JARDINERÍA.pdf"}
              rel="noreferrer"
              target={"_blank"}
            >
              Certificate
            </a>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step51;
